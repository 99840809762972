body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-stars {
  display: flex;
}

.feedback-grid::-webkit-scrollbar {
  height: 0px !important;
}

.feedback-grid::-webkit-scrollbar-thumb {
  border: 3px solid transparent !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  background-clip: content-box !important;
}

.feedback-grid::-webkit-scrollbar-thumb:hover {
  background-color: transparent !important;
}

.wrapper-class {
  background: #242a34;
  border: 1px solid;
  border-color: #96a090;
  min-height: 400px;
  border-radius: 1rem;
  overflow: hidden;
}
.editor-class {
  color: #eae5d8;
  font-weight: lighter;
  height: 100%;
}
.toolbar-class {
  border-top: 1rem;
}

.DraftEditor-editorContainer .public-DraftEditor-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bolder !important;
    color: var(--chakra-colors-yellowLight);
  }

  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.7em !important;
  }
  h3 {
    font-size: 1.5em !important;
  }
  h4 {
    font-size: 1em !important;
  }
  h5 {
    font-size: 0.83em !important;
  }
  h6 {
    font-size: 0.67em !important;
  }
  a {
    text-decoration: underline;
  }
}

